import React, { useEffect, useState } from 'react'
import { Layout } from '../components/layout'
import SEO from '../components/seo'
import { MenuSection } from '../components/sections/menu-section'
import { ContactSection } from '../components/sections/contact-section'
import { GallerySection } from '../components/sections/gallery-section'
import { HeaderSection } from '../components/sections/header-section'
import { LoadingIndicator } from '../components/loading-indicator'
import { TakeoutsSection } from '../components/sections/takeouts-section'
import { ReservationsSection } from '../components/sections/reservations-section'

const IndexPage = () => {
  const [headerLoaded, setHeaderLoaded] = useState(false)
  const [clientSide, setIsClientSide] = useState(false)

  useEffect(() => {
    if (window) {
      setIsClientSide(true)
    }
  }, [])

  if (!clientSide) return <LoadingIndicator/>

  return (
    <Layout loaded={headerLoaded}>
      <SEO title="ArchiesPizzeria"/>
      {(!headerLoaded) && <LoadingIndicator/>}
      <div>
        <HeaderSection loaded={headerLoaded} onLoad={() => setHeaderLoaded(true)}/>
        {headerLoaded && <><MenuSection/>
          <TakeoutsSection/>
          <ReservationsSection/>
          <ContactSection/>
          <GallerySection/></>}
      </div>
    </Layout>
  )
}
export default IndexPage
