import React from 'react'
import '../scss/_loading-indicator.scss'
import loading from '../images/logo.png'

export const LoadingIndicator = () => {
  return (
    <div className={'background'}>
      <div className={'content'}>
        <div className={'inside'}>
          <img src={loading} className={'loading-pizza'}/>
          <p>Archie's Pizzeria</p>
        </div>
      </div>
    </div>
  )
}
