import React from 'react'
import '../../scss/sections/_menu-section.scss'
import pizza from '../../images/pizza.jpg'
import lunchMenu from '../../images/menu/lunch.pdf'
import dinnerMenu from '../../images/menu/dinner.pdf'
import { Section } from './section'
import { MenuCard, MenuType } from '../menu-card'

export const MenuSection: React.FC = () => {
  return (
    <Section>
      <div className={'menu-image-section'}>
        <img className={'menu-image-section__image'} src={pizza} alt={'menu-image'}/>
        <header><h1 id={'menu'} className={'menu-image-section__text'}>{'Menu'}</h1></header>
      </div>
      <div className={'menu-cards'}>
        <a className={'menu-card'} href={lunchMenu}>
          <p className={'menu-card__link'}>Lunch</p>
        </a>
        <a className={'menu-card'} href={dinnerMenu}>
          <p className={'menu-card__link'}>Dinner</p>
        </a>
        <MenuCard type={MenuType.DRINKS}/>
      </div>
    </Section>
  )
}
