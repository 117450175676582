import React from 'react'
import '../scss/_hamburger.scss'

export interface HamburgerProps {
  hamburgerOpen: boolean
  setHamburgerOpen: (isOpen: boolean) => void;
}

export const Hamburger: React.FC<HamburgerProps> = ({ hamburgerOpen, setHamburgerOpen }) => {
  const handleOnClick = () => {
    setHamburgerOpen(!hamburgerOpen);
  }
  const className = hamburgerOpen ? `container--is-open` : `container`
  return (
    <div className={className} onClick={handleOnClick}>
      <div className={'bar1'}/>
      <div className={'bar2'}/>
      <div className={'bar3'}/>
    </div>
  )
}
