import React from 'react'
import '../../scss/sections/_section.scss'

export interface SectionProps {
  id?: string;
  title?: string;
  shrink?: boolean;
}

export const Section: React.FC<SectionProps> = ({ id, title, children, shrink }) => {
  const headerId = id ? id : title?.toLowerCase()
  const className = shrink ? `section section--shrink` : `section`;
  return (
    <div className={className}>
      {title && <h2 className={'section__title'} id={headerId}>{title}</h2>}
      {children}
    </div>
  )
}
