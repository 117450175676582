import React from 'react'
import '../scss/_menu-card.scss'
import { Link } from 'gatsby'

export enum MenuType {
  LUNCH = 'Lunch',
  DINNER = 'Dinner',
  DRINKS = 'Drinks',
  L3_LUNCH = 'L3 Lunch',
  L3_DINNER = 'L3 Dinner'
}

export interface MenuCardProps {
  type: MenuType;
}

export const MenuCard: React.FC<MenuCardProps> = ({ type }) => {
  const menuLink = `/${type.toString().toLowerCase()}-menu`.replace(/ /g, '-');
  return (
    <Link className={'menu-card'} to={menuLink}>
        <p className={'menu-card__link'}>{type?.toString()}</p>
    </Link>
  )
}
