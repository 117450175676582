import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: any;
  title?: string;
}

const SEO: React.FC<SEOProps> = ({ description = ``, lang = `en`, meta = [], title }) => {
  const pathname = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            image
          }
        }
      }
    `
  )
  const { url, image } = site.siteMetadata;

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `${url}${image}`,
        },
        {
          property: `og:url`,
          content: `${url}${pathname}`,
        }

      ].concat(meta)}
    />
  )
}

export default SEO;
