import React from 'react'
import '../../scss/sections/_header-section.scss'
import { Section } from './section'
import fireCoverOg from '../../images/fire-cover-og.jpg'

interface HeaderSectionProps {
  loaded: boolean
  onLoad: () => void;
}

export const HeaderSection: React.FC<HeaderSectionProps> = ({ loaded, onLoad }) => {
  return (
    <Section>
      <div className={'header-section'}>
        <div className={'image-section'}>
          <img onLoad={() => onLoad()}
               className={loaded ? 'image-section__image' : 'loading'}
               src={fireCoverOg}
               alt={'header image'}
          />
          <header>
            <h1 className={loaded ? 'image-section__text' : 'loading'}>{`Archie's Pizzeria`}</h1>
            <h2 className={loaded ? 'image-section__h2' : 'loading'}>Best pizza in the whole world! Pizzas made by hand, topped with the finest ingredients and baked in our wood fired oven to perfection.</h2>
          </header>
        </div>
      </div>
    </Section>
  )
}
