import React from 'react'
import '../../scss/sections/_gallery-section.scss'
import { Section } from './section'

export const GallerySection: React.FC = () => {
  return (
    <Section title={'Gallery'}>
      <iframe className={'gallery__embed'}
              src={'https://www.powr.io/instagram-feed/i/23782251#page'} frameBorder="0"/>
    </Section>
  )
}
