import React, { useState } from 'react'
import '../scss/_mailchimp.scss'
// @ts-ignore
import addToMailchimp from 'gatsby-plugin-mailchimp'
import loading from '../images/loading.png'

enum LoadingState { NotStarted, Loading, Loaded, Failed }

export const MailChimp: React.FC = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(LoadingState.NotStarted)
  const [error, setError] = useState('')

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!name) return setError('Please enter your name');
    if (!email) return setError('Please enter your email address');
    try {
      setError('')
      setLoading(LoadingState.Loading);
      const result = await addToMailchimp(email, { NAME: name })
      if (result.result !== 'success') throw Error(result.msg);
      setLoading(LoadingState.Loaded);
    } catch (error) {
      setLoading(LoadingState.Failed);
      setError(`Unable to sign up at this time. ${error.message}`)
    }
  }

  return (
    <form onSubmit={handleSubmit} className={'mailchimp'}>
      <h3>Sign up to our Newsletter!</h3>
      <input className={'mailchimp__input'} type={'text'} onChange={handleNameChange} placeholder={'Name'}/>
      <input className={'mailchimp__input'} type={'text'} onChange={handleEmailChange} placeholder={'Email Address'}/>
      {error && <p className={'mailchimp__error'}>{error}</p>}
      {loading === LoadingState.Loaded && <p className={'mailchimp__success'}>{`Thank you for subscribing!`}</p>}
      {loading === LoadingState.Loading && <MailChimpLoadingIndicator /> }
      {}
      <input className={'mailchimp__input--submit'} type={'submit'} value={'Sign Up'}/>
    </form>
  )
}

const MailChimpLoadingIndicator: React.FC = () => {
  return (
    <div className={'mailchimp-loading'}>
      <img src={loading} className={'mailchimp-loading-pizza'}/>
    </div>
  )
}
