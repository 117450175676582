import React, { useEffect, useState } from 'react'
import '../scss/_navigation.scss'
import logo from '../images/logo.png'
import { Hamburger } from './hamburger'

interface MenuLink {
  name: string;
  link: string;
}

interface NavigationProps {
  siteTitle: string;
  menuLinks: MenuLink[]
}

export const Navigation: React.FC<NavigationProps> = ({ siteTitle, menuLinks }) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false)
  const [width, setWidth] = useState<number>()

  useEffect(() => {
    if (window) {
      setWidth(window.innerWidth);
      window.addEventListener('resize', () => {
        setWidth(window.innerWidth) // todo optimise
      })
    }
  }, [])

  const scrollToTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0
    setHamburgerOpen(false)
  }

  const Overlay: React.FC = () => {
    return (
      <div className={'overlay'}>
        <ul className={'overlay__list'}>
          {menuLinks.map(menuLink => (
            <li key={menuLink.name} className={'overlay__list__item'}>
              <a href={menuLink.link} onClick={() => setHamburgerOpen(false)}
                 className={`overlay__list__item__link`}>{menuLink.name}</a>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const Bar: React.FC = props => {
    return (
      <div className={'navigation_sticky'}>
        <div className={'navigation'}>
          <a onClick={scrollToTop}><img className={'navigation__logo'} src={logo} alt={'logo image'}/></a>
          {props.children}
        </div>
        {hamburgerOpen && <Overlay/>}
      </div>
    )
  }

  if (!width || width < 600) {
    return (
      <Bar>
        <Hamburger hamburgerOpen={hamburgerOpen} setHamburgerOpen={setHamburgerOpen}>hamburger</Hamburger>
      </Bar>
    )
  }

  return (
    <Bar>
      <ul className={'navigation__list'}>
        {menuLinks.map(menuLink => (
          <li key={menuLink.name} className={'navigation__list__item'}>
            {/*{menuLink.name === 'menu'}*/}
            <a href={menuLink.link} className={`navigation__list__item__link`}>{menuLink.name}</a>
          </li>
        ))}
      </ul>
    </Bar>
  )
}
