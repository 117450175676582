import React from 'react'
import '../../scss/sections/_contact-section.scss'
import { Section } from './section'

export const ContactSection: React.FC = () => {
  return (
    <Section title={'Contact'}>
      <div className={'contact'}>
        <h3>Hours</h3>
        <p>Monday - Sunday, 11am - 10pm</p>
        <br/>
        <h3>Phone Number</h3>
        <a href={'tel:+6495235111'} className={'contact__link'}><span className={'bracket'}>(</span>09<span
          className={'bracket'}>)</span> 523 5111</a>
        <br/>
        <br/>
        <h3>Address</h3>
        <p>63 Davis Crescent, Newmarket, Auckland 1023</p>
      </div>
      <iframe
        className={'contact__map'}
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12767.921110274707!2d174.7775429!3d-36.8668909!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc5024b12a8990ca5!2sArchie&#39;s%20Restaurant%20%26%20Pizzeria!5e0!3m2!1sen!2snz!4v1586486496508!5m2!1sen!2snz"
        frameBorder="0" aria-hidden="false">
      </iframe>
    </Section>
  )
}
