import React from 'react'
import '../../scss/sections/_takeouts-section.scss'
import { Section } from './section'

export const TakeoutsSection: React.FC = () => {
  return (
    <Section id={'takeouts'} title={'Takeouts'} shrink={true}>
      <div className={'takeouts'}>
        <p className={''}>Takeaway and delivery options available.</p>
        <p className={''}>We offer both our Pizza, Main and dessert menu to takeout and enjoy at home for both lunch and dinner.</p>
        <p className={''}>Orders can be placed by phone and picked up in-store or delivered locally. </p>
        <div className={'space'} />
        <div className={'space'} />
      </div>
    </Section>
  )
}
