import React from 'react'
import '../../scss/sections/_reservations-section.scss'
import { Section } from './section'
import { MailChimp } from '../mail-chimp'

export const ReservationsSection: React.FC = () => {
  return (
    <Section id={'reservations'} title={'Reservations'} shrink={true}>
      <div className={'reservations'}>
        <p className={''}>We offer reservations for lunch and dinner.</p>
        <p className={''}>To make a reservation please call us to check availability and book.</p>
        <a className={'reservations__link'} href="tel:095235111">(09) 523 5111</a>
        <br/><br/>
        <p className={''}>If you have any requests or questions these can be answered by one of our team. </p>
        <p className={'bold'}>Please note we do not take bookings over Facebook</p>
        <h3 className={''}>We always welcome walk-ins.</h3>
      </div>
      <br /><br /><br />
      <MailChimp />
    </Section>
  )
}
