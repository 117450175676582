import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Navigation } from './navigation'
import '../scss/_layout.scss'
import { AlertBanner } from './alert-banner'

interface LayoutProps {
  loaded: boolean;
}

export const Layout: React.FC<LayoutProps> = ({ loaded, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
           }
        }
      }
    }
  `)

  if (!loaded) return <main>{children}</main>

  return (
    <>
      {/*<AlertBanner/>*/}
      <Navigation menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title}/>
      <main>{children}</main>
      <footer className={'footer'}>
        <a className={'footer__link'} href={'https://toohey.dev'}>Website built by James Toohey</a>
      </footer>
    </>
  )
}
